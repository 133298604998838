<template>
  <div class="carousel-container">
    <Carousel
      :slides="slides"
      :interval="15000"
      :textes="textes"
      :titres="titres" 
      class="carou"
    />
  </div>
</template>

<script>
import Carousel from "@/components/Accueil/banner/carousel.vue";

export default {
  name: "HeaderComponent",
  props: ["slides", "textes", "titres", "height"],
  components: {
    Carousel,
  },
  methods: {},
};
</script>
<style>
.carousel-container {
    justify-content: center !important;
    padding: 0 !important;
    margin: 0 auto !important;
    width: 100% !important;
    max-width: 1400px;
}

.Acc-headd {
  z-index: 998;
}

.carou {
  object-fit: cover;
  height: 360px;
}

@media (max-width: 500px) {
  .carou {
    height: 460px;
  }
}
</style>
