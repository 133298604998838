<template>
  <div class="carousel" ref="crsl">
    <div class="carousel-inner">
      <carousel-indicators 
        v-if="indicators" 
        :total="slides.length" 
        :current-index="currentSlide" 
        @switch="switchSlide($event)"
      ></carousel-indicators>
      
      <carousel-item 
        v-for="(slide, index) in slides" 
        :slide="slide" 
        :key="`item-${index}`" 
        :current-slide="currentSlide" 
        :index="index" 
        :direction="direction"
        :texte="textes[index]" 
        :localites="titres" 
        @mouseenter="stopSlideTimer" 
        @mouseleave="startSlideTimer"
      ></carousel-item>
      
      <carousel-controls 
        v-if="controls" 
        @prev="prev" 
        @next="next"
      ></carousel-controls>
    </div>
  </div>
</template>

<script>
import CarouselItem from "@/components/Accueil/banner/carouselItem.vue";
import CarouselControls from "@/components/Accueil/banner/carouselControls.vue";
import CarouselIndicators from "@/components/Accueil/banner/carouselIndicators.vue";

export default {
  name: "CptCarousel",
  props: {
    slides: {
      type: Array,
      required: true,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    indicators: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 10000, // Fixé à 10 secondes (10 000 ms)
    },
    textes: {
      type: Array,
      required: false,
    },
    titres: {
      type: Array,
      required: false,
    },
  },
  components: { CarouselItem, CarouselControls, CarouselIndicators },
  data: () => ({
    currentSlide: 0,
    slideInterval: null,
    direction: "right",
  }),
  methods: {
    setCurrentSlide(index) {
      this.currentSlide = index;
    },
    prev(step = -1) {
      const index = this.currentSlide > 0 ? this.currentSlide + step : this.slides.length - 1;
      this.setCurrentSlide(index);
      this.direction = "left";
      this.startSlideTimer();
    },
    next(step = 1) {
      const index = this.currentSlide < this.slides.length - 1 ? this.currentSlide + step : 0;
      this.setCurrentSlide(index);
      this.direction = "right";
      this.startSlideTimer();
    },
    startSlideTimer() {
      this.stopSlideTimer(); // Arrêter l'ancien minuteur avant de démarrer le nouveau
      console.log('Interval set to:', this.interval); // Debug pour vérifier l'intervalle
      this.slideInterval = setInterval(() => {
        this.next();
      }, this.interval); // Utiliser l'intervalle correct (10 secondes)
    },
    stopSlideTimer() {
      if (this.slideInterval) {
        clearInterval(this.slideInterval); // Arrêter le minuteur en cours
        this.slideInterval = null;
      }
    },
    switchSlide(index) {
      const step = index - this.currentSlide;
      if (step > 0) {
        this.next(step);
      } else {
        this.prev(step);
      }
    },
  },
  mounted() {
    this.startSlideTimer(); // Démarrer le minuteur à 10 secondes
  },
  beforeUnmount() {
    this.stopSlideTimer(); // Assurez-vous que le minuteur est arrêté lors de la destruction du composant
  },
};
</script>

<style scoped>
.carousel {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
