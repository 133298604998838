<template>
  <div class="guinea-bar">
    <div class="bar red"></div>
    <div class="bar yellow"></div>
    <div class="bar green"></div>
  </div>
  <nav class="Acc-nav">
    <div class="container-nav">
      <div class="logo-section">
        <img src="@/assets/images/sim-guinee.jpg" alt="logo" @click="$router.push({ path: '/' })"
          style="cursor: pointer">
        <div class="texte">
          <h4 class="fr">{{ $t('description.tete') }}</h4>
          <!-- <hr>
          <p class="en">Information System on Rice and Onion Markets in Guinea</p> -->
        </div>
      </div>
    

      <div class="language-section">
        <div @click="toggleLanguageDropdown" class="select">
          {{ getLanguageName(selectedLanguage) }}
          <img :src="getFlagSrc(selectedLanguage)" class="image" alt="">
        </div>
        <div v-show="isLanguageDropdownOpen" class="select_content">
          <div v-for="(language, index) in languages" :key="index" @click="changeLanguage(language.value)"
            class="select_item">
            {{ language.name }}
            <img :src="language.flagSrc" class="image" alt="logo_flag">
          </div>
        </div>
      </div>
      <div class="burger">
        <div class="line1"></div>
        <div class="line1"></div>
        <div class="line1"></div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'CptNavBar',
  data() {
    return {
      selectedLanguage: 'fr',
      languages: [
        { value: 'fr', name: 'Français', flagSrc: require(`@/assets/images/france-flag-icon.svg`) },
        { value: 'en', name: 'English', flagSrc: require(`@/assets/images/unit.svg`) },
      ],
      isLanguageDropdownOpen: false,
      isAuthenticated:false,
    }
  },
  
  methods: {
    toggleLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen
    },
    changeLanguage(language) {
      this.selectedLanguage = language
      this.$i18n.locale = language
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen
    },
    getFlagSrc(language) {
      return this.languages.find(l => l.value === language).flagSrc
    },
    getLanguageName(language) {
      return this.languages.find(l => l.value === language).name
    }
  },
  mounted() {
    const burger = document.querySelector('.burger')
    const Navmenu = document.querySelector('.Acc-menu')
    burger.addEventListener('click', () => {
      burger.classList.toggle("burger-active")
      Navmenu.classList.toggle("Acc-menu-active")
    })
    console.log(localStorage.getItem('authToken'));
      
    if( localStorage.getItem('authToken'))
    {
      this.isAuthenticated="true";
    }
     
    
  },
}
</script>

<style scoped>
.Acc-nav {
  background-color: white;
  /* Couleur de fond du header */
  color: #139864;
  /* Couleur du texte en blanc */
}

/* Barre aux couleurs de la Guinée */
.guinea-bar {
  display: flex;
  height: 5px;
}



.container-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 10px;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-section img {
  height: 4rem;
  cursor: pointer;
}

.texte {
  margin-left: 10px;
  font-family: 'Playfair Display', serif;
}

.texte p {
  margin: 0;
  color: #ffffff;
  /* Texte en blanc */
}

hr {
  border-color: #ffffff;
  /* La ligne sous le texte du logo en blanc */
}

.Acc-menu {
  display: flex;
  gap: 30px;
  list-style: none;
}

.Acc-lien {
  color: #139864;
  /* Couleur des liens en blanc */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.Acc-lien:hover {
  color: #FFA800;
  /* Couleur au survol plus claire pour contraste */
}

.language-section {
  display: flex;
  align-items: center;
}

.select {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #139864;
  /* Couleur du texte en blanc */
}

.select img {
  height: 13px;
  margin-left: 5px;

}

/* Barre aux couleurs de la Guinée */
.guinea-bar {
  display: flex;
  height: 5px;
  width: 100%;
}

.bar {
  flex: 1;
  /* Chaque barre prend 1/3 de la largeur totale */
}

.red {
  background-color: #ce1126;
  /* Rouge */
}

.yellow {
  background-color: #fcd116;
  /* Jaune */
}

.green {
  background-color: #009460;
  /* Vert */
}

.select_content {
  position: absolute;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  padding: 10px;
  top: 50px;
  right: 0;
  width: 150px;
  z-index: 10;
}

.select_item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #000000;
  /* Texte du menu déroulant en noir */
  cursor: pointer;
}

.select_item:hover {
  background-color: #d0f0e0;
  /* Couleur de fond du menu au survol */
}

.burger div {
  background-color: #ffffff;
  /* Couleur des lignes du menu burger en blanc */
}

.image {
  width: 30px;
  height: 30px
}

@media (max-width: 880px) {
  .burger {
    display: block;
    cursor: pointer;
  }

  .Acc-menu {
    position: absolute;
    top: 100px;
    left: 0;
    flex-direction: column;
    background-color: var(--blanc);
    width: 100%;
    display: none;
    text-align: center;
  }



  .burger.burger-active+.Acc-menu {
    display: flex;
  }
}
</style>
