import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil.vue'
import Login from '../views/Login.vue' // Importation du composant Login

// Simuler l'authentification via localStorage (peut être remplacé par un autre système)
const isAuthenticated = () => !!localStorage.getItem('authToken'); // Si 'authToken' est présent, l'utilisateur est authentifié

const routes = [
  {
    path: '/',
    name: 'accueil',
    component: Accueil,
    props: true,
    meta: { requiresAuth: true }, // Protection de la route
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        top: 10,
        behavior: 'smooth'
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// Vérifier les routes qui nécessitent une authentification
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next({ name: 'login' }); // Rediriger vers la page de login si non authentifié
  } else {
    next(); // Continuer la navigation
  }
});

export default router;
