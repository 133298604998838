<template>
  <div class="header">
    <div class="header-top">
      <div class="logo-section">
        <img src="@/assets/images/ANASA.jpeg" alt="logo" height="30" class="logo">
      </div>

      <div class="header-title">
        <h1 class="title">RÉPUBLIQUE DE GUINÉE</h1>
        <hr class="dashed-line">
        <div class="motto">
          <span class="motto-item red">Travail</span>
          <span class="motto-item yellow">Justice</span>
          <span class="motto-item green">Solidarité</span>
        </div>
        <h2 class="subtitle">MINISTÈRE DE L'AGRICULTURE ET DE L'ÉLEVAGE</h2>
        <h3 class="agency-name">Agence Nationale des Statistiques Agricoles et Alimentaires (ANASA)</h3>
      </div>

      <div class="logo-section">
        <img src="@/assets/images/gn.png" alt="ministry logo" class="logo">
        <img src="@/assets/images/armoirie1.png" alt="Guinea flag" class="logo1">
      </div>
    </div>

    <!-- <div class="guinea-bar">
      <div class="bar red"></div>
      <div class="bar yellow"></div>
      <div class="bar green"></div>
    </div> -->
    <nav class="Acc-nav">
      <div class="container-nav">
        <ul class="Acc-menu">
          <li><router-link to="/" class="Acc-lien">{{ $t('navbar.accueil') }}</router-link></li>
          <li><router-link to="/#stock" class="Acc-lien">{{ $t('navbar.prix') }}</router-link></li>
          <li><router-link to="/#about" class="Acc-lien">{{ $t('navbar.cles') }}</router-link></li>
          <li><router-link to="/#magasin" class="Acc-lien">{{ $t('navbar.magasin') }}</router-link></li>
          <li><router-link to="/#partenaire" class="Acc-lien">{{ $t('navbar.partenaire') }}</router-link></li>
          <li><router-link to="/#contact" class="Acc-lien">{{ $t('navbar.contact') }}</router-link></li>
        </ul>
        <div class="language-section">
          <div @click="toggleLanguageDropdown" class="select">
            {{ getLanguageName(selectedLanguage) }}
            <img :src="getFlagSrc(selectedLanguage)" class="image" alt="">
          </div>
          <div v-show="isLanguageDropdownOpen" class="select_content">
            <div v-for="(language, index) in languages" :key="index" @click="changeLanguage(language.value)"
              class="select_item">
              {{ language.name }}
              <img :src="language.flagSrc" class="image" alt="logo_flag">
            </div>
          </div>
          <div class="burger">
            <div class="line1"></div>
            <div class="line1"></div>
            <div class="line1"></div>
          </div>
        </div>

      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'CptNavBar',
  data() {
    return {
      selectedLanguage: 'fr',
      languages: [
        { value: 'fr', name: 'Français', flagSrc: require(`@/assets/images/france-flag-icon.svg`) },
        { value: 'en', name: 'English', flagSrc: require(`@/assets/images/unit.svg`) },
      ],
      isLanguageDropdownOpen: false,
      isAuthenticated: false,
    }
  },

  methods: {
    toggleLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen
    },
    changeLanguage(language) {
      this.selectedLanguage = language
      this.$i18n.locale = language
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen
    },
    getFlagSrc(language) {
      return this.languages.find(l => l.value === language).flagSrc
    },
    getLanguageName(language) {
      return this.languages.find(l => l.value === language).name
    }
  },
  mounted() {
    const burger = document.querySelector('.burger')
    const Navmenu = document.querySelector('.Acc-menu')
    burger.addEventListener('click', () => {
      burger.classList.toggle("burger-active")
      Navmenu.classList.toggle("Acc-menu-active")
    })
    console.log(localStorage.getItem('authToken'));

    if (localStorage.getItem('authToken')) {
      this.isAuthenticated = "true";
    }


  },
}
</script>
<style scoped>
.header {
  width: 100%;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-bottom: 2px solid #ddd;
}

.logo-section img {
  /* width: 80px !important; */
  cursor: pointer;
}
.logo-section1 img {
  width: 100px;
  cursor: pointer;
}
.logo-section,
.logo-section1 {
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-section img,
.logo-section1 img {
  
  height: auto; /* Garde les proportions des logos */
}

.logo1 {
  max-width: 60px; /* Réglage spécifique pour l'image "logo1" */
  height: auto;
}
.header-title {
  text-align: center;
  flex-grow: 1;
  margin-top: 5px; /* Ajustez le haut pour déplacer le titre */
}

.title {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 4px;
}

.motto {
  /* margin-top: 10px; */
  font-size: 16px;
  font-weight: bold;
}

.motto-item {
  margin: 0 5px;
}

.red {
  color: #ce1126;  
}

.yellow {
  color: #fcd116; 
}

.green {
  color: #009460;  
}

.subtitle {
  font-size: 16px;
  margin: 5px 0;
  text-transform: uppercase;
}

.agency-name {
  font-size: 14px;
  margin: 0;
}

.header-right {
  display: flex;
  align-items: center;
}

.logo {
  width: 140px;
  /* height: 130px; */
  margin-right: 15px;
}
 
.dashed-line {
  border: none;
  border-bottom: 2px dashed black;
  width: 10%;
  margin: 5px auto;
}
.guinea-flag {
  width: 50px;
}


.logo-section {
  display: flex;
  align-items: center;
}

 
.texte {
  margin-left: 10px;
  font-family: 'Playfair Display', serif;
}

.header-title {
  text-align: center;
}

.header-title h1 {
  font-size: 1.5rem;
  margin: 0;
}

.header-title h2 {
  font-size: 1.2rem;
  margin: 0;
  color: black;
  font-weight: bolder !important;
}

 

.guinea-bar {
  display: flex;
  height: 5px;
  width: 100%;
}

.bar {
  flex: 1;
}

.red {
  color: #CE1126;
}

.yellow {
  color: #FCD116;
}

.green {
  color: #009460;
}

.Acc-nav {
  background-color: white;
  color: #139864;
  font-size: large;
}

.container-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 10px;
}

.Acc-menu {
  display: flex;
  gap: 30px;
  list-style: none;
}

.Acc-lien {
  color: #139864;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.Acc-lien:hover {
  color: #FFA800;
}

.language-section {
  display: flex;
  align-items: center;
}

.select {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #139864;
}

.select img {
  height: 13px;
  margin-left: 5px;
}

.select_content {
  position: absolute;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  padding: 10px;
  top: 50px;
  right: 0;
  width: 150px;
  z-index: 10;
}

.select_item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #000000;
  cursor: pointer;
}

.select_item:hover {
  background-color: #d0f0e0;
}

.burger div {
  background-color: #ffffff;
}

.image {
  width: 30px;
  height: 30px;
}

@media (max-width: 880px) {
  .burger {
    display: block;
    cursor: pointer;
  }

  .Acc-menu {
    position: absolute;
    top: 100px;
    left: 0;
    flex-direction: column;
    background-color: var(--blanc);
    width: 100%;
    display: none;
    text-align: center;
  }

  .burger.burger-active+.Acc-menu {
    display: flex;
  }
}
</style>