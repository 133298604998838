<template>
    <Navbar class="Acc-headd"/>
    <div class="login-container">
      <div class="login-form">
        <h2>Connexion</h2>
        <p>Accédez aux statistiques des marchés</p>
  
        <form @submit.prevent="login">
          <div class="input-group">
            <label for="username">Login *</label>
            <input type="text" id="username" v-model="username" required placeholder="Entrez votre login" />
          </div>
  
          <div class="input-group">
            <label for="password">Mot de passe *</label>
            <input type="password" id="password" v-model="password" required placeholder="Entrez votre mot de passe" />
          </div>
  
          <div class="error" v-if="error">{{ error }}</div>
  
          <button type="submit" class="login-button">Se connecter</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
    import Navbar from '../components/section/navbar.vue';
  
    export default {
      name: 'ViewsLogin',
      components: {
        Navbar
      },
      data() {
        return {
          username: '',
          password: '',
          error: ''
        };
      },
      methods: {
        login() {
          if (this.username === 'admin' && this.password === 'simguinee') {
            // Stocker le token d'authentification dans localStorage
            localStorage.setItem('authToken', '12345'); // Simuler un token
            sessionStorage.setItem('authToken', '12345');
            // Rediriger l'utilisateur vers la page d'accueil
            this.$router.push({ name: 'accueil' });
          } else {
            // Afficher un message d'erreur
            this.error = 'ERREUR : Identifiants incorrects';
          }
        }
      }
    };
  </script>
  
  <style scoped>
  /* Conteneur principal prenant toute la hauteur de l'écran */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Toute la hauteur de la fenêtre */
    background-color: #f5f5f5;
  }
  
  /* Formulaire centré */
  .login-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 360px;
    text-align: center;
  }
  
  /* Styles pour le texte et les champs */
  h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  p {
    margin-bottom: 1.5rem;
    color: #777;
  }
  
  .input-group {
    margin-bottom: 1.5rem;
    text-align: left;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .error {
    color: red;
    margin-bottom: 1rem;
  }
  
  /* Bouton de connexion */
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #ffc107;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #e0a800;
  }
  </style>
  