<template>
 
    
    <!-- <div class="corps-footer-menu">
      <nav>
        <ul class="menu">
          <li><router-link to="/#" class="Acc-lien">{{ $t('navbar.accueil')}}</router-link></li>
        <li><router-link to="/#stock" class="Acc-lien">{{ $t('navbar.prix')}}</router-link></li>
        <li><router-link to="/#magasin" class="Acc-lien">{{ $t('navbar.magasin')}}</router-link></li>
        <li><router-link to="/#partenaire" class="Acc-lien">{{ $t('navbar.partenaire')}}</router-link></li>
        <li><router-link to="/#contact" class="Acc-lien">{{ $t('navbar.contact')}}</router-link></li>
        </ul>
      </nav>
    </div> -->
 
    <div class="corps-footer-links">
      <h3 style="color: #139864;">{{ $t('footer.liens')}}</h3>
      <div class="links-container">
        <a href="https://www.agriculture.gov.gn/"><img src="../../assets/images/partenaires/Ministere_Agriculture.png" alt="Ministère de l'Agriculture et de l'Elevage"></a>
        <a href="http://https//pdaig.org.gn"><img src="../../assets/images/partenaires/Pdaig.fw_.png" alt="PDAIG"></a>
        <a href="https://www.afd.fr/fr"><img src="../../assets/images/partenaires/AFD.fw_.png" alt="AFD"></a>
        <a href="https://www.banquemondiale.org/fr/home"><img src="../../assets/images/partenaires/Banque-Mondiale.fw_.png" alt="Banque Mondiale"></a>
        <a href="https://www.fao.org/home/fr"><img src="../../assets/images/partenaires/LogoFAO.png" alt="FAO"></a>
      </div>
    </div>

    <div class="corps-footer-text">
      <p>{{ $t('footer.copyright')}}</p>
    </div>
 
</template>
<script>
export default {
  name: 'CptFooter'

}
</script>
<style scoped lang="css">
.corps-footer-links {
  text-align: center;
  margin-bottom: 20px;
}

.corps-footer-links h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.links-container img {
  max-height: 50px;
}
.corps-footer {
  max-width: 100%;
  height: 200px; /* Réduction de la hauteur */
  margin: 0 auto;
  background-color: var(--vert);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px 0; /* Réduction du padding */
}

.corps-footer-logo .logo {
  display: flex;
  flex-direction: initial;
  align-items: center;
  text-align: center;
  color: var(--blanc);
}

.corps-footer-logo img {
  width: 70px !important; /* Réduction de la taille de l'image */
  margin-right: 5px;
}

.corps-footer-menu ul {
  list-style-type: none;
  display: flex;
  padding: 0; /* Suppression des marges */
}

.corps-footer-menu ul li a {
  text-decoration: none;
  margin-right: 30px; /* Réduction de l'espace entre les liens */
  color: var(--blanc);
  font-weight: 500;
}

.corps-footer-menu ul li a:hover {
  color: var(--noir);
}

.corps-footer-reseau {
  width: 100%;
  height: 40px; /* Réduction de la hauteur */
  display: flex;
  justify-content: center;
}

.corps-footer-reseau-content {
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.corps-footer-reseau a {
  background-color: var(--blanc);
  height: 30px; /* Réduction de la taille des icônes sociales */
  width: 30px;
  padding: 10px;
  border-radius: 8px; /* Légère réduction du radius */
  margin: 10px; /* Réduction de l'espacement autour */
}

.corps-footer-reseau i {
  font-size: 24px; /* Réduction de la taille des icônes */
  top: 3px;
  left: 4px;
}

.corps-footer-text p:hover {
  color: var(--noir);
}

.corps-footer-text {
  background: #139864;
    color: white;
    font-size: 15px;
    /* padding: 10px 0; */
    border-top: 1px solid #cad0d6;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
}

/* Ajustements pour les petits écrans */
@media (max-width: 685px) {
  .corps-footer {
    height: auto;
    padding: 10px;
  }

  .corps-footer-menu ul {
    flex-direction: column;
    align-items: center;
  }

  .corps-footer-menu ul li a {
    margin-right: 0;
  }

  .corps-footer-reseau a {
    margin: 0 5px;
  }
}

@media (max-width: 447px) {
  .corps-footer-logo img {
    width: 50px !important; /* Réduction supplémentaire pour petits écrans */
  }
  
  .corps-footer-logo a {
    font-size: 12px;
  }
}

</style>